<template>
  <div class="select_opt_area" :id="'oog_area_' + cntrSeq">
    <!-- Case02. OT/FR 시작 -->
    <div class="select_opt_area mt10">
      <table class="tbl_col t2">
        <colgroup>
          <col style="width:15%">
          <col style="width:15%">
          <col style="width:15%">
          <col style="width:15%">
          <col style="width:13%">
          <col style="width:auto">
        </colgroup>
        <tbody>
          <tr>
            <th scope="col">Length</th>
            <th scope="col">Width</th>
            <th scope="col">Height</th>
            <th scope="col">Cargo Weight</th>
            <th scope="col">Tare Included</th>
            <th scope="col">SIMULATION</th>
          </tr>
          <tr>
            <td class="vat">
              <e-input-number
                v-model="oogInfo.orgLength"
                :is-comma="true"
                :input-class="'col_8'"
                :id="'orgLength_' + cntrSeq"
                :maxlength="10"
                @input="checkSize('orgLength')"
              >
              </e-input-number> ㎝
              <div
                v-if="overLength !== '' && overLength > 0"
                class="txt_size"
              >
                Over Size: (F) +{{ setComma(overLength) }}㎝<br>(B) +{{ setComma(overLength) }}㎝
              </div>
            </td>
            <td class="vat">
              <e-input-number
                v-model="oogInfo.orgWidth"
                :is-comma="true"
                :input-class="'col_8'"
                :id="'orgWidth_' + cntrSeq"
                :maxlength="10"
                @input="checkSize('orgWidth')"
              >
              </e-input-number> ㎝
              <div
                v-if="overWidth !== '' && overWidth > 0"
                class="txt_size"
              >
                Over Size: (L) +{{ setComma(overWidth) }}㎝<br>(R) +{{ setComma(overWidth) }}㎝
              </div>
            </td>
            <td class="vat">
              <e-input-number
                v-model="oogInfo.orgHeight"
                :is-comma="true"
                :input-class="'col_8'"
                :id="'orgHeight_' + cntrSeq"
                :maxlength="10"
                @input="checkSize('orgHeight');checkValidationOOG('orgHeight')"
              >
              </e-input-number> ㎝
              <div
                v-if="overHeight !== '' && overHeight > 0"
                class="txt_size"
              >
                Over Size: +{{ setComma(overHeight) }}㎝
              </div>
            </td>
            <td class="vat">
              <e-input-number
                :id="'frgtWt_' + cntrSeq"
                v-model="oogInfo.frgtWt"
                :is-comma="true"
                :input-class="'col_8'"
                @input="checkWeight($event, cntrSeq); checkValidationOOG('frgtWt')"
                :maxlength="13"
              >
              </e-input-number> kg
            </td>
            <td class="vat tac">
              <input
                :id="'tareWtYn_' + cntrSeq"
                v-model="oogInfo.tareWtYn"
                type="checkbox"
                true-value="Y"
                false-value="N"
                :checked="oogInfo.tareWtYn == 'Y'"
                @change="handleChange()"
              >
              <label :for="'tareWtYn_' + cntrSeq" />
            </td>
            <td rowspan="3">
              <div class="img_simulation">
                <div :class="'div_simulation div_' + simulation">
                </div>
              </div>
              <p v-if="$ekmtcCommon.isNotEmpty(deadSpace)" class="text_center mt5">Dead Space : <span class="font_medium">{{ deadSpace }}TEU</span></p>
            </td>
          </tr>
          <tr>
            <th scope="col">ITEM Q’TY</th>
            <th scope="col">Stowage Request</th>
            <th scope="col">Break Bulk</th>
            <th scope="col" colspan="2">Commodity</th>
          </tr>
          <tr>
            <td>
              <e-input-number
                :id="'itemQty_' + cntrSeq"
                v-model="oogInfo.itemQty"
                :is-comma="true"
                :input-class="'itemQty'"
                :maxlength="13"
                @input="checkValidationOOG('itemQty')"
              >
              </e-input-number>
            </td>
            <td>
              <select
                :id="'underDckCd_' + cntrSeq"
                v-model="oogInfo.underDckCd"
                @change="handleChange()"
              >
                <option v-for="cvo in unserDckList" :key="cvo.cd" :value="cvo.cd">{{ cvo.cdNm }}</option>
              </select>
            </td>
            <td>
              <select
                v-model="oogInfo.bulkYn"
                :id="'bulkYn_' + cntrSeq"
                @change="handleChange()"
              >
                <option value="">{{ $t('msg.CSBK100.062') }}</option>
                <option value="Y">Yes</option>
                <option value="N">No</option>
              </select>
            </td>
            <td colspan="2">
              <e-auto-complete-commodity
                :ctr-cd="memberDetail.userCtrCd"
                :cd-nm="oogInfo.cmdtDsc"
                @change="handleCallbackChange('cmdtDsc', ...arguments)"
                :focus-force="true"
                :id="'cmdtDsc_' + cntrSeq"
              />
            </td>
            <!-- <td colspan="2">
              <select
                v-model="oogInfo.oogCmdtNm"
                :id="'oogCmdtNm_' + cntrSeq"
                class="col_48p"
                @change="findCmdtCdOog('CHANGE')"
              >
                <option value="">---------------------------------</option>
                <option v-for="cvo in cmdtCdOogList" :key="cvo.cdNm" :value="cvo.cdNm">{{ cvo.cdNm }}</option>
              </select>
              <select
                v-model="oogInfo.cmdtCd"
                :id="'cmdtCd_' + cntrSeq"
                class="col_48p"
                @change="handleChange()"
              >
                <option value="">---------------------------------</option>
                <option v-for="cvo in cmdtCdOogDscList" :key="cvo.cd" :value="cvo.cd">{{ cvo.dsc }}</option>
              </select>
            </td> -->
          </tr>
          <tr>
            <th scope="col">
              File Upload
            </th>
            <td colspan="2">
              <div class="filebox">
                <a
                  @click="openUploadPopup()"
                  :class="isFileUpload() ? 'blue' : 'gray file'"
                  class="button sm"
                >
                  {{ $t('msg.CSBK100.205') }}
                </a>
              </div>
            </td>
            <th scope="col">
              Best Lashing
            </th>
            <td colspan="3">
              <div class="filebox down_btn_area" @click="oogFileDown(oogInfo.cmdtCd)">
                <label for="file">{{ $t('msg.CSBK100.206') }}</label>
              </div>
            </td>
          </tr>
          <tr>
            <th colspan="6">
              <div class="filebox" style="text-align: left; padding-left: 5px;">
                <a class="td_underline" style="color: red;" href="#" @click.prevent="excelDownload('Letter_of_Indemnity_FR_OT.xlsx')">{{ $t('msg.CSBK100.551') }}</a> <!-- Download LOI Template -->
              </div>
            </th>
          </tr>
          <tr>
            <th scope="col">
              Request Remark
            </th>
            <td colspan="5">
              <textarea v-model="oogInfo.respCont" @change="handleChange" />
            </td>
          </tr>
          <tr>
            <td colspan="6">
              <div class="desc_list mt0">
                <ul>
                  <li>
                    {{ $t('msg.CSBK100.207') }}<br>
                    {{ $t('msg.CSBK100.207_1') }}<br>
                    {{ $t('msg.CSBK100.207_2') }}<br>
                    {{ $t('msg.CSBK100.208') }}<br>
                    {{ $t('msg.CSBK100.208_1') }}
                  </li>
                  <li>{{ $t('msg.CSBK100.209') }}</li>
                  <li>{{ $t('msg.CSBK100.210') }}</li>
                </ul>
              </div>
            </td>
          </tr>
          <tr>
            <th scope="row">Status</th>
            <td colspan="2">
              <span class="span_scg">{{ oogInfo.apvStsNm }}</span>
            </td>
            <th scope="row">Ref No.</th>
            <td colspan="2">
              <span class="span_scg">{{ oogInfo.scgAppNo }}</span>
            </td>
          </tr>
          <tr>
            <th scope="row">KMTC Remark</th>
            <td colspan="5">
              <textarea
                class="txt_border_none"
                :value="oogInfo.apvCont"
                readonly
              />
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <!-- Case02. OT/FR 끝 -->
    <win-layer-pop>
      <component
        v-if="uploadComponent"
        :is="uploadComponent"
        @close="closeUploadPopup"
        :parent-info="uploadParam"
        @set-dext-file-info="uploadCallbackFunc"
        :category-info="uploadCategoryInfo"
      />
    </win-layer-pop>
  </div>
</template>

<script>
import { rootComputed } from '@/store/helpers'
import commons from '@/api/services/commons'
import oogs from '@/api/rest/trans/oogs'
import EInputNumber from '@/components/common/EInputNumber'
import DgOogCerti from '@/api/rest/trans/dgOogCerti'
import srInquiry from '@/api/services/srInquiry'
import bookings from '@/api/rest/trans/bookings'

export default {
  name: 'BookingSrTableOog',
  components: {
    EInputNumber,
    'win-layer-pop': () => import('@/pages/trans/popup/WinLayerPop'),
    'doc-upload-pop': () => import('@/pages/trans/popup/DocUploadPop'),
    'e-auto-complete-commodity': () => import('@/components/common/EAutoCompleteCommodity'),
    'oog-lashing-pop': () => import('@/pages/trans/popup/OogLashingPop')
    // 'dg-oog-certi-pop': () => import('@/pages/trans/popup/DgOogCertiPop')
  },
  props: {
    parentInfo: {
      type: Object,
      default: null
    },
    kmdVslCheck: {
      type: String,
      default: 'N'
    },
    emptyFlag: {
      type: String,
      default: 'F'
    },
    cgoTypCd: {
      type: String,
      default: ''
    },
    index: {
      type: Number,
      default: 0
    },
    saveFlag: {
      type: String,
      default: 'ADD'
    },
    detailBkgNo: {
      type: String,
      default: ''
    },
    grsWtTon: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      uploadComponent: null,
      uploadType: '',
      uploadParam: [],
      uploadCategoryInfo: [],
      commonCodes: {},
      cmdtCdOogList: [],
      cmdtCdOogDscList: [],
      unserDckList: [],
      overLength: '',
      overWidth: '',
      overHeight: '',
      cntrSeq: '',
      cntrTypCd: '',
      cntrSzCd: '',
      oldCgoTypCd: '',
      oldEmptyFlag: '',
      simulation: '',
      deadSpace: '',
      oogInfo: {
        orgLength: '',
        orgWidth: '',
        orgHeight: '',
        itemQty: '',
        underDckCd: '',
        bulkYn: 'N',
        oogCmdtNm: '',
        frgtWt: '',
        tareWtYn: 'N',
        cmdtCd: '',
        cmdtDsc: '',
        itemCont: '',
        reqRno: '',
        uploadFileList: []
      },
      overSizeInfo: {
        FR20: { length: 560, width: 243, height: 221 },
        FR40: { length: 1177, width: 243, height: 195 },
        OT20: { length: 588, width: 234, height: 231 },
        OT40: { length: 1204, width: 235, height: 234 }
      },
      oldGrsWtTon: ''
    }
  },
  computed: {
    ...rootComputed
  },
  watch: {
    async parentInfo () {
      await this.initPage()
    },
    cgoTypCd () {
      if (this.cgoTypCd !== this.oldCgoTypCd) {
        this.resetData()
      }
    },
    emptyFlag () {
      if (this.emptyFlag !== this.oldEmptyFlag) {
        this.resetData()
      }
    },
    grsWtTon () {
      if (this.grsWtTon !== this.oldGrsWtTon) {
        this.oldGrsWtTon = this.grsWtTon

        if (this.cgoTypCd === '03' &&
          this.cntrTypCd === 'HC' &&
          this.cntrSzCd === '45') {
          this.oogInfo.frgtWt = this.grsWtTon
        }
      }
    }
  },
  created () {
    console.log('@@@@ BookingSrTableOog created')
    this.initPage(true)
  },
  methods: {
    handleChange () {
      console.log('@@@@ enter handleChange')
      console.log({
        ...this.oogInfo
      })

      this.$emit('update', {
        ...this.oogInfo,
        _simulation: this.simulation
      }, this.index)
    },
    handleCallbackChange (key, value) {
      console.log('@@@@ enter handleCallbackChange')
      console.log('@@@@ key: ', key)
      console.log('@@@@ value: ', value)
      this.oogInfo[key] = value.cdNm
      this.oogInfo.cmdtCd = value.cd

      if (this.$ekmtcCommon.isNotEmpty(value.cdNm)) {
        const frm = document.querySelector('#srForm')
        const selector = frm.querySelector('#cmdtDsc_' + this.cntrSeq)
        this.$ekmtcCommon.hideErrorTooltip(selector)
      }

      this.$emit('update', {
        ...this.oogInfo,
        _simulation: this.simulation
      }, this.index)
    },
    async initPage (isCreated = false) {
      const arrCdId = ['VS081', '01208', '10064']
      this.commonCodes = await commons.getCommonsCodesCommon(arrCdId)

      const tempCmdtCdOogList = this.commonCodes.VS081

      if (tempCmdtCdOogList !== undefined && tempCmdtCdOogList !== null) {
        this.cmdtCdOogList = tempCmdtCdOogList.filter((item1, idx) => {
          return tempCmdtCdOogList.findIndex((item2) => {
              return item1.cdNm === item2.cdNm
          }) === idx
        })
      }

      if (this.commonCodes['01208'] !== undefined && this.commonCodes['01208'] !== null) {
        this.unserDckList = this.commonCodes['01208'].filter(vo => {
          return vo.cd === '01' || vo.cd === '02'
        })
      }

      // OOG 첨부파일 카테고리
      const uploadCategoryInfo = this.commonCodes['10064']

      this.uploadCategoryInfo = uploadCategoryInfo.map(tvo => {
        const vo = {
          category: tvo.cdNm,
          value: tvo.cd
        }
        return vo
      })

      this.initParam(isCreated)
    },
    initParam (isCreated = false) {
      const parentOog = {
        orgLength: this.parentInfo.orgLength || '',
        orgWidth: this.parentInfo.orgWidth || '',
        orgHeight: this.parentInfo.orgHeight || '',
        itemQty: this.parentInfo.itemQty || '',
        underDckCd: this.parentInfo.underDckCd || '01',
        bulkYn: this.parentInfo.bulkYn || 'N',
        oogCmdtNm: this.parentInfo.oogCmdtNm || '',
        frgtWt: this.parentInfo.frgtWt || '',
        tareWtYn: this.parentInfo.tareWtYn || 'N',
        cmdtCd: this.parentInfo.cmdtCd || '',
        cmdtDsc: this.parentInfo.cmdtDsc || '',
        itemCont: this.parentInfo.itemCont || '',
        reqRno: this.parentInfo.reqRno || '',
        uploadFileList: this.parentInfo.uploadFileList || [],
        apvStsNm: this.parentInfo.apvStsNm || '',
        scgAppNo: this.parentInfo.scgAppNo || '',
        apvCont: this.parentInfo.apvCont || ''
      }

      this.simulation = this.parentInfo._simulation || ''

      this.cntrSeq = this.parentInfo.cntrSeq
      this.cntrTypCd = this.parentInfo.cntrTypCd
      this.cntrSzCd = this.parentInfo.cntrSzCd
      // this.cgoTypCd = this.parentInfo.cgoTypCd
      // this.emptyFlag = this.parentEmptyFlag
      this.oogInfo = parentOog
      this.findCmdtCdOog('', isCreated)

      if (this.cgoTypCd === '03' &&
        this.cntrTypCd === 'HC' &&
        this.cntrSzCd === '45') {
        this.oogInfo.frgtWt = this.grsWtTon

        $('.itemQty').eq(this.index).attr('disabled', true)
        $('[id*=orgLength_]').eq(this.index).attr('disabled', true)
        $('[id*=orgWidth_]').eq(this.index).attr('disabled', true)
        $('[id*=orgHeight_]').eq(this.index).attr('disabled', true)
      } else {
        $('.itemQty').eq(this.index).attr('disabled', false)
        $('[id*=orgLength_]').eq(this.index).attr('disabled', false)
        $('[id*=orgWidth_]').eq(this.index).attr('disabled', false)
        $('[id*=orgHeight_]').eq(this.index).attr('disabled', false)
      }
    },
    checkValidationOOGAll () {
      let prefix = ['orgLength', 'orgWidth', 'orgHeight', 'frgtWt', 'itemQty', 'bulkYn', 'cmdtDsc']

      if (this.cgoTypCd === '03' &&
        this.cntrTypCd === 'HC' &&
        this.cntrSzCd === '45') {
        prefix = ['frgtWt', 'bulkYn', 'cmdtDsc']
      } else {
        prefix = ['orgLength', 'orgWidth', 'orgHeight', 'frgtWt', 'itemQty', 'bulkYn', 'cmdtDsc']
      }

      let isOk = true
      let failCnt = 0
      prefix.forEach(el => {
        if (!this.checkValidationOOG(el)) {
          failCnt++
        }
      })

      if (failCnt > 0) {
        isOk = false
      }

      return isOk
    },
    checkValidationOOG (prefix) {
      console.log('checkValidationOOG prefix @@@@ ', prefix)

      let isOk = true
      const cntrSeq = this.cntrSeq
      const frm = document.querySelector('#srForm')
      const id = prefix + '_' + cntrSeq
      const selector = frm.querySelector('#' + id)

      const cntrSzCd = this.cntrSzCd
      const cntrTypCd = this.cntrTypCd
      const cgoTypCd = this.cgoTypCd
      const emptyFlag = this.emptyFlag
      const oogInfo = this.oogInfo

      this.$ekmtcCommon.hideErrorTooltip(selector)

      if (prefix === 'orgLength') {
        if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }

        if (cntrTypCd === 'FR' && this.kmdVslCheck === 'N') {
          if (cntrSzCd === '20') {
            if (Number(selector.value) > 560) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.199')) // 'Length(cm)은 560까지 입력 가능합니다.'
              isOk = false
            }
          } else if (Number(selector.value) > 1177) {
            this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.200')) // 'Length(cm)은 1,177까지 입력 가능합니다.'
            isOk = false
          }
        } else if (cntrTypCd === 'OT') {
          if (cntrSzCd === '20') {
            if (Number(selector.value) > 588) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.201')) // 'Length(cm)은 588까지 입력 가능합니다.'
              isOk = false
            }
          } else {
            if (Number(selector.value) > 1204) {
              this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.202')) // 'Length(cm)은 1,204까지 입력 가능합니다.'
              isOk = false
            }
          }
        }
      }

      if (prefix === 'orgWidth') {
        if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else {
          if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(selector.value) > 234) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.203'))
                isOk = false
              }
            } else {
              if (Number(selector.value) > 235) {
                this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.204'))
                isOk = false
              }
            }
          }
        }
      }

      if (prefix === 'orgHeight') {
        if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }

        let flagCnt = 0
        if (cgoTypCd === '02' && emptyFlag !== 'E') { // OOG
          if (cntrTypCd === 'FR') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 560) {
                flagCnt++
              }

              if (Number(oogInfo.orgWidth) > 243) {
                flagCnt++
              }

              if (Number(oogInfo.orgHeight) > 221) {
                flagCnt++
              }
            } else {
              if (Number(oogInfo.orgLength) > 1177) {
                flagCnt++
              }

              if (Number(oogInfo.orgWidth) > 243) {
                flagCnt++
              }

              if (Number(oogInfo.orgHeight) > 195) {
                flagCnt++
              }
            }
          } else if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgHeight) > 231) {
                flagCnt++
              }
            } else {
              if (Number(oogInfo.orgHeight) > 234) {
                flagCnt++
              }
            }
          }

          if (flagCnt === 0) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Please check cargo size again! (Out of-gauge cargo)')
            isOk = false
          }
        } else if (cgoTypCd === '03' && emptyFlag !== 'E') {
          const length = frm.querySelector('#orgLength_' + cntrSeq)
          const width = frm.querySelector('#orgWidth_' + cntrSeq)
          const height = frm.querySelector('#orgHeight_' + cntrSeq)

          if (cntrTypCd === 'FR') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 560) {
                this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgWidth) > 243) {
                this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgHeight) > 221) {
                this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }
            } else {
              if (Number(oogInfo.orgLength) > 1177) {
                this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgWidth) > 243) {
                this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgHeight) > 195) {
                this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }
            }
          } else if (cntrTypCd === 'OT') {
            if (cntrSzCd === '20') {
              if (Number(oogInfo.orgLength) > 588) {
                this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgWidth) > 234) {
                this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgHeight) > 231) {
                this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }
            } else {
              if (Number(oogInfo.orgLength) > 1204) {
                this.$ekmtcCommon.showErrorTooltip(length, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgWidth) > 235) {
                this.$ekmtcCommon.showErrorTooltip(width, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }

              if (Number(oogInfo.orgHeight) > 234) {
                this.$ekmtcCommon.showErrorTooltip(height, 'Please check cargo size again! (In-gauge cargo)')
                isOk = false
              }
            }
          }
        }
      }

      if (prefix === 'itemQty') {
        if (emptyFlag !== 'E' && this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      if (prefix === 'bulkYn') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        }
      }

      if (prefix === 'frgtWt') {
        if (this.$ekmtcCommon.isEmpty(selector.value)) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('msg.CSBK100.147'))
          isOk = false
        } else if (Number(selector.value) < 100) {
          this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.023'))
          isOk = false
        }

        if (cntrTypCd === 'OT') {
          if (((Number(oogInfo.frgtWt) > 21980) && cntrSzCd === 20) ||
              ((Number(oogInfo.frgtWt) > 26500) && cntrSzCd === 40)
            ) {
            this.$ekmtcCommon.showErrorTooltip(selector, 'Regarding cargo\'s weight exceeds container\'s maximum capacity. Please proceed this cargo as Break Bulk.')
            isOk = false
          }
        } else if (cntrTypCd === 'FR') {
          let overCnt = 0

          if (cntrSzCd === 20) {
            if ((Number(oogInfo.orgLength) <= 100 && Number(oogInfo.frgtWt) > 21000) ||
              (Number(oogInfo.orgLength) <= 150 && Number(oogInfo.frgtWt) > 21400) ||
              (Number(oogInfo.orgLength) <= 200 && Number(oogInfo.frgtWt) > 21900) ||
              (Number(oogInfo.orgLength) <= 250 && Number(oogInfo.frgtWt) > 22500) ||
              (Number(oogInfo.orgLength) <= 300 && Number(oogInfo.frgtWt) > 23300) ||
              (Number(oogInfo.orgLength) <= 350 && Number(oogInfo.frgtWt) > 24200) ||
              (Number(oogInfo.orgLength) <= 400 && Number(oogInfo.frgtWt) > 24500) ||
              (Number(oogInfo.orgLength) <= 450 && Number(oogInfo.frgtWt) > 26800) ||
              (Number(oogInfo.orgLength) <= 500 && Number(oogInfo.frgtWt) > 28500) ||
              (Number(oogInfo.orgLength) > 500 && Number(oogInfo.frgtWt) > 31100)) {
                if (!confirm('Additional dunnage work is required in order to distribute concentrated cargo weight or can be handled as a Break Bulk cargo.')) {
                  return false
                }
            }

            if (Number(oogInfo.frgtWt) > 31260) {
              this.$ekmtcCommon.alertDefault('Regarding cargo\'s weight exceeds container\'s maximum capacity. Please proceed this cargo as Break Bulk.')
              return
            }
          } else if (cntrSzCd === 40) {
            if ((Number(oogInfo.orgLength) <= 100 && Number(oogInfo.frgtWt) > 25400) ||
                (Number(oogInfo.orgLength) <= 200 && Number(oogInfo.frgtWt) > 25700) ||
                (Number(oogInfo.orgLength) <= 300 && Number(oogInfo.frgtWt) > 26100) ||
                (Number(oogInfo.orgLength) <= 400 && Number(oogInfo.frgtWt) > 26700) ||
                (Number(oogInfo.orgLength) <= 500 && Number(oogInfo.frgtWt) > 27400) ||
                (Number(oogInfo.orgLength) <= 600 && Number(oogInfo.frgtWt) > 28400) ||
                (Number(oogInfo.orgLength) <= 700 && Number(oogInfo.frgtWt) > 29500) ||
                (Number(oogInfo.orgLength) <= 800 && Number(oogInfo.frgtWt) > 30900) ||
                (Number(oogInfo.orgLength) <= 900 && Number(oogInfo.frgtWt) > 32600) ||
                (Number(oogInfo.orgLength) <= 1000 && Number(oogInfo.frgtWt) > 34800) ||
                (Number(oogInfo.orgLength) <= 1100 && Number(oogInfo.frgtWt) > 37000) ||
                (Number(oogInfo.orgLength) > 1100 && Number(oogInfo.frgtWt) > 39900)) {
              if (!confirm('Additional dunnage work is required in order to distribute concentrated cargo weight or can be handled as a Break Bulk cargo.')) {
                return false
              }
            }

            if (Number(oogInfo.frgtWt) > 39400) {
              this.$ekmtcCommon.alertDefault('Regarding cargo\'s weight exceeds container\'s maximum capacity. Please proceed this cargo as Break Bulk.')
              return
            }
          }
        }

        if (Math.round(((oogInfo.frgtWt / 1000) / ((oogInfo.orgLength / 100) * (oogInfo.orgWidth / 100))) * 100) / 100 > 3.0) {
          if (!confirm('Exceed the limitation of Load square meter(3.0) that can cause the container damage and accident.\n- Load Square Meter = (Weight (Ton) / Length (m) x width (m)) ≤ 3.0\n(**In case Width > 2.4, Width = 2.4)')) {
            return false
          }
        }
      }
      if (prefix === 'cmdtDsc') {
        if (this.$ekmtcCommon.isEmpty(oogInfo.cmdtDsc)) {
          this.$ekmtcCommon.showErrorTooltip(
            selector,
            this.$t('msg.CSBK100.389')
          )
          isOk = false
        }
      }
      this.handleChange()

      return isOk
    },
    checkSize (flag) {
      const cntrInfo = this.cntrTypCd + this.cntrSzCd
      const overSizeInfo = this.overSizeInfo[cntrInfo]

      if (overSizeInfo === undefined) {
        return
      }

      if (flag === 'orgLength') {
        const val = this.oogInfo.orgLength
        if (Number(val) > overSizeInfo.length) {
          this.overLength = Math.round((val - overSizeInfo.length) / 2)
        } else {
          this.overLength = 0
        }
      } else if (flag === 'orgWidth') {
        const val = this.oogInfo.orgWidth
        if (Number(val) > overSizeInfo.width) {
          this.overWidth = Math.round((val - overSizeInfo.width) / 2)
        } else {
          this.overWidth = 0
        }
      } else if (flag === 'orgHeight') {
        const val = this.oogInfo.orgHeight
        if (Number(val) > overSizeInfo.height) {
          this.overHeight = val - overSizeInfo.height
        } else {
          this.overHeight = 0
        }
      }

      if (this.cntrTypCd === 'FR') {
        if (Number(this.overLength) > 0 && Number(this.overWidth) === 0 && Number(this.overHeight) === 0) {
          this.simulation = 'ol'
          this.deadSpace = '2'
        } else if (Number(this.overLength) > 0 && Number(this.overWidth) > 0 && Number(this.overHeight) === 0) {
          this.simulation = 'owol'
          this.deadSpace = this.cntrSzCd === '20' ? '4' : '10'
        } else if (Number(this.overLength) === 0 && Number(this.overWidth) > 0 && Number(this.overHeight) === 0) {
          this.simulation = 'ow'
          this.deadSpace = this.cntrSzCd === '20' ? '2' : '4'
        } else if (Number(this.overLength) === 0 && Number(this.overWidth) > 0 && Number(this.overHeight) > 0) {
          this.simulation = 'ohow'
          this.deadSpace = this.cntrSzCd === '20' ? '5' : '10'
        } else if (Number(this.overLength) === 0 && Number(this.overWidth) === 0 && Number(this.overHeight) > 0) {
          this.simulation = 'oh'
          this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
        } else if (Number(this.overLength) > 0 && Number(this.overWidth) === 0 && Number(this.overHeight) > 0) {
          this.simulation = 'ohol'
          this.deadSpace = this.cntrSzCd === '20' ? '5' : '6'
        } else if (Number(this.overLength) > 0 && Number(this.overWidth) > 0 && Number(this.overHeight) > 0) {
          this.simulation = 'ohowol'
          this.deadSpace = this.cntrSzCd === '20' ? '9' : '22'
        } else {
          this.simulation = ''
          this.deadSpace = ''
        }
      } else if (this.cntrTypCd === 'OT') {
        if (Number(this.overHeight) > 0) {
          this.simulation = 'open'
          this.deadSpace = this.cntrSzCd === '20' ? '1' : '2'
        } else {
          this.simulation = ''
          this.deadSpace = ''
        }
      }

      this.checkValidationOOG(flag)
    },
    setComma (num) {
      return this.$ekmtcCommon.changeNumberFormat(num, { isComma: true })
    },
    checkWeight (value, cntrSeq) {
      const frm = document.querySelector('#srForm')
      const id = 'frgtWt_' + cntrSeq
      const selector = frm.querySelector('#' + id)
      this.$ekmtcCommon.hideErrorTooltip(selector)

      let val = value
      val = val.replace(/,/g, '')
      if (val !== '' && Number(val) < 100) {
        this.$ekmtcCommon.showErrorTooltip(selector, this.$t('js.restriction.023'))
      }
    },
    findCmdtCdOog (evt, isCreated = false) {
      this.oogInfo.cmdtCdOogDsc = ''
      const cmdtNm = this.oogInfo.cmdtDsc
      if (this.$ekmtcCommon.isNotEmpty(cmdtNm)) {
        const cmdtList = this.commonCodes.VS081
        if (cmdtList !== undefined && cmdtList !== null) {
          const cmdtCdOogDscList = cmdtList.filter(vo => vo.cdNm === cmdtNm)

          if (evt === 'CHANGE') {
            if (cmdtCdOogDscList !== null && cmdtCdOogDscList.length > 0) {
              this.oogInfo.cmdtCd = cmdtCdOogDscList[0].cd
            }
          }

          this.cmdtCdOogDscList = cmdtCdOogDscList
        }
      }

      if (isCreated === false) {
        this.handleChange()
      }
    },
    resetData () {
      this.oldCgoTypCd = this.cgoTypCd
      this.oldEmptyFlag = this.emptyFlag

      this.checkSize('orgLength')
      this.checkSize('orgWidth')
      this.checkSize('orgHeight')
      this.checkValidationOOGAll()
    },
    async oogFileDown (cmdtCd) {
      // oogs.oogFileDown(cmdtCd)
      if (!this.$ekmtcCommon.isEmpty(cmdtCd)) {
        await oogs.oogFileDown(cmdtCd).then(res => {
          if (window.navigator && window.navigator.msSaveOrOpenBlob) {
            window.navigator.msSaveOrOpenBlob(res.data, trdFileInfo.fileNm)
          } else {
            if (res.data) {
              const header_dis = res.headers['content-disposition']
              if (header_dis) {
                const name = header_dis.split('filename=')[1]
                const url = window.URL.createObjectURL(new Blob([res.data], { type: res.headers['content-type'] }))
                const link = document.createElement('a')
                link.href = url
                link.setAttribute('download', name)
                document.body.appendChild(link)
                link.click()
                link.remove()

                window.URL.revokeObjectURL(url)
              } else {
                this.selectFunc = ''
                this.openPopup('oog-lashing-pop')
              }
            }
          }
        }).catch(() => {
          this.$ekmtcCommon.alertDefault(this.$t('js.common.005'))
        })
      } else {
        this.selectFunc = ''
        this.openPopup('oog-lashing-pop')
      }
    },
    openPopup (compNm) {
      this.uploadComponent = null
      this.uploadComponent = compNm
      this.$ekmtcCommon.layerOpen(
        '#oog_area_' + this.cntrSeq + ' > .popup_dim'
      )
    },
    closePoup () {
      this.uploadComponent = null
      this.$ekmtcCommon.layerClose(
        '#oog_area_' + this.cntrSeq + ' > .popup_dim'
      )
    },
    async openUploadPopup () {
      if (!this.$ekmtcCommon.isEmpty(this.oogInfo.reqRno) && !this.$ekmtcCommon.isEmpty(this.detailBkgNo)) {
        const srContainerFileInfo = await srInquiry.getSrContainerFileInfo(this.oogInfo.reqRno, this.detailBkgNo, 'O')

        this.oogInfo.uploadFileList = [...[], ...srContainerFileInfo.data.uploadFileList]
      }

      this.oogInfo.uploadFileList.map(obj => {
        obj.size = obj.fileSize
        //fileId가 현재 DB는 INT형인데 fileDownload를 위해서 쿼리 스트링 형식으로 바꿔줘야함
        //obj.fileIdOrg = obj.fileId
        obj.fileId = 'http://trans.ekmtc:9000/trans/trans/file-download?reqRno=' + obj.reqRno + '&atchFileSeq=' + obj.atchFileSeq + '&type=DG_OOG_CERTI_BK'
        obj.fileSeq = obj.atchFileSeq
      })

      this.uploadParam = [...[], ...this.oogInfo.uploadFileList]

      this.uploadComponent = 'doc-upload-pop'
      this.$ekmtcCommon.layerOpen('#oog_area_' + this.cntrSeq + ' > .popup_dim')
    },
    async uploadCallbackFunc (fileList) {
      if (!this.$ekmtcCommon.isEmpty(this.detailBkgNo) && !this.$ekmtcCommon.isEmpty(this.cntrSeq)) {
        const uploadFileList = this.$_.cloneDeep(fileList)

        uploadFileList.forEach(element => {
          if (element.newFlag === undefined || element.newFlag === '') {
            element.fileId = ''
          }
        })

        const dgOogParam = {
          reqRno: this.oogInfo.reqRno,
          count: this.cntrSeq,
          type: 'O',
          dgUpLoadFlag: 'EDIT',
          bkgNo: this.detailBkgNo,
          uploadFileInfos: uploadFileList
        }

        const resultObject = await DgOogCerti.bookingSrPopDgOogUpload(dgOogParam)

        if (resultObject.data.resultCode === 'SUCC') {
          fileList.forEach(element => {
            element.fileId = ''
            delete element.newFlag
            delete element.new
          })
        }
      }

      this.oogInfo.uploadFileList = [...[], ...fileList]

      this.$ekmtcCommon.layerClose('#oog_area_' + this.cntrSeq + ' > .popup_dim')

      this.handleChange()
    },
    isFileUpload () {
      if (this.oogInfo.uploadFileList !== undefined && this.oogInfo.uploadFileList.length > 0) {
        return true
      }
      return false
    },
    closeUploadPopup () {
      this.$ekmtcCommon.layerClose('#oog_area_' + this.cntrSeq + ' > .popup_dim')
    },
    //엑셀 템플릿 다운로드
    async excelDownload (fileName) {
      const result = await bookings.excelDownload(fileName)
      //blob 생성
      const blob = new Blob([result.data], { type: result.headers['content-type'] })

      if (window.navigator.msSaveOrOpenBlob) { //browser Check - IE
        window.navigator.msSaveOrOpenBlob(blob, fileName)
      } else { //browser Check - 그 외
        //<a>태그에 URL화 시킨 blob객체를 연결시킨 후 다운로드
        const link = document.createElement('a')
        const url = window.URL.createObjectURL(blob)
        link.href = url
        link.target = '_self'
        link.download = fileName
        link.click()

        //url 사용 후 메모리에서 제거
        window.URL.revokeObjectURL(url)
      }
    }
  }
}
</script>

<style scoped>
  .down_btn_area > label {margin-left: 0px;}
</style>
